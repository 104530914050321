import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getPostObj } from "../utils/utils"

import { Heading } from "../components/Hero"
import { Section, Columns } from "../components/Containers"
import { Text } from "../components/Core"

const BasicPage = ({ pageContext, location, data }) => {
  const { post, language } = getPostObj(
    pageContext,
    data.allBasicPagesJson.nodes[0]
  )

  return (
    <Layout language={language} className="basic-page">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <Heading heading={post.heading} blurb={post.blurb ? post.blurb : ""} />

      <Section>
        <Columns sideColumnsSize={3}>
          <div className="column">
            <Text text={post.body} />
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const basicPageQuery = graphql`
  query basicPage($title: String!) {
    allBasicPagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        # blurb
        body
      }
    }
  }
`

export default BasicPage
